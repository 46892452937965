import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'Login',
        component: () => import('@/views/LoginView.vue'),
        meta: {
            title: '登录',
            isAuth: false
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/IndexView.vue'),
        meta: {
            title: '主页',
            isAuth: false
        }
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: () => import('@/views/GalleryView.vue'),
        meta: {
            title: 'Midjourney Feed',
            isAuth: true
        }
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('@/views/Privacy.vue'),
        meta: {
            title: 'Privacy',
            isAuth: false
        }
    },
    {
        path: '/channels',
        name: 'Channels',
        component: () => import('@/views/ChannelsView.vue'),
        meta: {
            title: 'Discord | "midjourney" | MJ',
            isAuth: true
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
